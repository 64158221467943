<template>
  <!-- 股东会决议有效性 -->
  <div>
    <!-- <title-center name="股东决议有效性" :rules="true"></title-center> -->
    <base-form
      :componentList="shareholderMeetingConfig"
      :formAttrs="{
        model: formData,
        labelWidth: '140px',
        disabled
      }"
      :showBtns="false"
      class="formData"
      ref="shareholderMeeting"
      @iconClick='iconClick'
    />
  </div>
</template>

<script>
import BaseForm from '@/components/common/base-form/base-form.vue'
// import titleCenter from '../../components/title-center.vue'
import { shareholderMeetingConfig } from '../utils/config'
export default {
  components: { BaseForm },
  props: {
    gysId: String, // 供应商ID
    resultValidTimeType: String, // 决议有效性类型
    shareholderResultValidInfo: Object, // 股东会决议有效性信息DTO，
    singleTransactionType: String,
    financeAmount: {},
    resultValidTimeFileType: String, // 有效性信息文件类型 0.股东会决议或股东决定(默认) 1.董事会决议 2.全选
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formData: {
        resultValidTimeFileType: '0',
        resultValidTimeType: '0',
        shareholderResultValidInfo: {
          time: [],
          resultValidTimeStart: '',
          resultValidTimeEnd: '',
          guaranteeQuota: '',
          busiApproveTime: '',
          validTimeNum: '',
          validTimeUnit: '',
          keyId: '',
          surplusGuaranteeQuota: '',
          validRemark: ''
        }
      }
    }
  },
  // 计算属性 类似于data概念
  computed: {
    shareholderMeetingConfig () {
      return shareholderMeetingConfig(this)
    }

  },
  // 监控data中的数据变化
  watch: {
    resultValidTimeFileType (val) {
      // this.formData.resultValidTimeFileType = val
      this.$set(this.formData, 'resultValidTimeFileType', val)
      console.log(this.formData.resultValidTimeFileType, 9999999999999)
    },
    resultValidTimeType (val) {
      this.formData.resultValidTimeType = val
    },
    shareholderResultValidInfo: {
      handler (val) {
        this.formData.shareholderResultValidInfo = val
        this.$set(this.formData, 'validRemark', val.validRemark)
      },
      deep: true
    },
    formData: {
      handler (val) {
        this.$emit('changeResolutionFrom', val)
      },
      deep: true
    }
  },
  // 方法集合
  methods: {
    iconClick () {
      const Str = '1.股东会/董事会签署成员名字、表决占股/人数比例' + '\n' + '2. XXX（身份证号：XXXXXX96003126238）出具实控人《承诺函》（非全体股东签署条件）' + '\n' + '3.单笔，本次有效/非单笔，一年内有效（预计2021.10.11-2022.10.11，具体以签署时间为准），融资及担保额度x万元，本次业务审批（预计2021.10.11-2022.4.11，具体以签署时间为准）在有效期内' + '\n' + '4.框架股决已于2021年9月25日由股东XXX、股东XXX签署，一年内有效（202X.X.XX-2022.9.25），融资及担保额度x万元，已使用X万元（融资期限），剩余y万元可覆盖本次融资本息，本次业务审批（预计2021.10.11-2022.4.11，具体以签署时间为准）在有效期内 （如无可删除）'
      if (this.formData.shareholderResultValidInfo.validRemark) {
        this.$confirm('是否覆盖股东会决议有效性总结?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$set(this.formData, 'validRemark', Str)
          // this.$emit(this.formData.shareholderResultValidInfo, 'validRemark', Str)
        }).catch(() => {

        })
      } else {
        this.$set(this.formData, 'validRemark', Str)
        // this.$set(this.formData.shareholderResultValidInfo, 'validRemark', Str)
      }
    },
    getValidInfo (data) {
      console.log(data, 3)
      this.$emit('getValidInfo', data)
    },
    changeSingleTransactionType (data) {
      this.$emit('changeSingleTransactionType', data)
    },
    // 修改年月日
    changeValidTimeUnit (val) {
      this.$emit('changeValidTimeUnit', val)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
/deep/ .el-form-item__content{
  height: auto !important;
}
.formData{
  /deep/ .el-form-item__label{
  font-size: 16px;
  font-family: SourceHanSansSC-bold;
  color: #000;
  font-weight: 400;
}
}

</style>
